export default class APITestser {
    async Get() {
        return "getting"
    }
    async Post() {
        return "posting"
    }
    async Update() {
        return "deleting"
    }
    async Delete() {
        return "deleting"
    }
}