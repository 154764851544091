import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    packageList: [],
    customerList: [],
    siteList: [],
    packageGroups: {},
    selectedPackages: {},
    frequency: 'Annually',
    frequencyMonths: 12,
    commonSettings: {},
    currentPricingStage: 1,
    xtraUsers: 0,
    xtraCompanies: 0,
    isSubmitting: false,
    submissionStatus: null,
    kyc_content: {
        sectors: [{
            label: 'Select Sector',
            value: ''
        }],
        company_group: [{
            label: 'Select Registration Type',
            value: ''
        }],
        town: [{
            label: 'Select Town',
            value: ''
        }],
        country: [{
            label: 'Select Country',
            value: ''
        }],
    }
}

export const packageSlice = createSlice({
    name: 'packageSlice',
    initialState,
    reducers: {
        setPackageList: (state, action) => {
            state.packageList = action.payload
        },
        setPackageGroups: (state, action) => {
            state.packageGroups = action.payload
        },
        setCustomerList: (state, action) => {
            state.customerList = action.payload
        },
        setSiteList: (state, action) => {
            state.siteList = action.payload
        },
        setSelectedPackageList: (state, action) => {
            state.selectedPackages = action.payload
        },
        setFrequency: (state, action) => {
            state.frequency = action.payload
        },
        setCommonSettings: (state, action) => {
            state.commonSettings = action.payload
        },
        setFrequencyMonths: (state, action) => {
            state.frequencyMonths = action.payload
        },
        setCurrentPricingStage: (state, action) => {
            state.currentPricingStage = action.payload
        },
        setKYCContent: (state, action) => {
            state.kyc_content = action.payload
        },
        setXtraUsers: (state, action) => {
            state.xtraUsers = action.payload
        },
        setXtraCompanies: (state, action) => {
            state.xtraCompanies = action.payload
        },
        setIsSubmitting: (state, action) => {
            state.isSubmitting = action.payload
        },
        setSubmissionStatus: (state, action) => {
            state.submissionStatus = action.payload
        }
    }
})
export const {
    setPackageList,
    setSelectedPackageList,
    setPackageGroups,
    setCustomerList,
    setSiteList,
    setFrequency,
    setCommonSettings,
    setFrequencyMonths,
    setCurrentPricingStage,
    setKYCContent,
    setXtraUsers,
    setXtraCompanies,
    setIsSubmitting,
    setSubmissionStatus
} = packageSlice.actions
export default packageSlice.reducer