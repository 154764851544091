export const whyContent = [
    {
        icon: 'benefit1.svg',
        title: 'Empowering Micro & Medium Enterprises in Africa.',
        content: `StartApp ERP is designed to promote financial inclusion by providing SMEs with the tools and capabilities they need to succeed.`

    },
    {
        icon: 'efficiency.svg',
        title: 'Operational Efficiency',
        content: `Automate manual or routine tasks, implement smarter workflows and gain efficiency`
    },
    {
        icon: 'realtime.svg',
        title: 'Realtime Reporting',
        content: `Analyze and compare every aspect of your business from a single point of view`
    },
    {
        icon: 'reducedcost.svg',
        title: 'Reduced Costs',
        content: `Reduced paperwork and optimize daily routines, decrease repetitive tasks.
        Speed up business operations that involve information
        requests and approvals, such as logistics, stock and supply management, and others.`
    },
]

export const benefitsContent = [
    {
        title: 'Competitive Advantage',
        content: `With so many enterprise resource planning advantages the software provides,
         users can see improvements within multiple departments. Implementing ERP 
         software helps to keep you ahead of the competition`
    },
    {
        title: 'Improved Process Efficiency',
        content: `Eliminates repetitive processes and greatly reduces the need to manually 
        enter information, which not only improves user productivity, but eliminates the possibility of 
        inaccurate data that may cost the business.`
    },
    {
        title: 'Data Reliability',
        content: `Provides reliable data that can be accessed from different 
        locations (if implemented in the cloud) and through multiple devices 
        including tablets and smartphones`
    },
    {
        title: 'Customized Reporting',
        content: `StartApp ERP helps make reporting more customizable, fit to meet all of your needs. 
        With improved reporting capabilities, your company can respond to complex data requests easily.`
    },
    {
        title: 'Mobility',
        content: `Users have access to a centralized database from anywhere you work and from 
        different devices including your laptop, tablet or cell phone.`
    },
    {
        title: 'Integrated Information',
        content: `Every business want to be smart with its financials and avoid making 
        costly mistakes. With one source of accurate, 
        real-time information and operations costs, StartApp ERP is the best solution.`
    },
]