import Card from 'components/shared/Card'
import Column from 'components/shared/Column'
import Grid from 'components/shared/Grid'
import Image from 'components/shared/Image'
import Pill from 'components/shared/Pill'
import Row from 'components/shared/Row'
import { IMG_PATH } from 'constants'
import { Unique } from 'functions/common'
import React from 'react'
import parse from 'html-react-parser'

export default function Feature({ data }) {

    return (
        <Card className="shadow border-0 bg-[#fff]">
            <Row className="feature-card" align="start">
                <Image className="feature-img" path={`${IMG_PATH}/${data.module.icon}.png`} size={50} />
                <Column className="ml-5">
                    <h3 className='font-bold text-gray-700 feature-module-title'>{data.module.module}</h3>
                    <p className='text-md text-gray-600'>{parse(data.module.description)}</p>
                    <Grid>
                        {
                            data.features && data.features.length > 0 &&
                            data.features.map(feature => (
                                <Row key={Unique()}>
                                    <Pill color='#161741' innerPillColor='white' size={15} />
                                    <span className='text-sm'>{feature.feature}</span>
                                </Row>
                            ))
                        }
                    </Grid>
                </Column>
            </Row>
        </Card>
    )
}
