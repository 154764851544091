import Execute from 'api/axios/Execute'
import { API_CONFIG } from 'api/config'
import Button from 'components/shared/Button'
import Card from 'components/shared/Card'
import Column from 'components/shared/Column'
import Container from 'components/shared/Container'
import Grid from 'components/shared/Grid'
import InputField from 'components/shared/InputField'
import Row from 'components/shared/Row'
import Select from 'components/shared/Select'
import { useFormik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { BsInfoCircle } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { basicSchema } from 'schema/validation'
import { setCurrentPricingStage, setCustomerList, setIsSubmitting, setKYCContent, setSiteList, setSubmissionStatus } from 'store/packages/packageSlice'
import SubmissionLoader from './SubmissionLoader'
import $ from 'jquery'
import Loader from 'views/common/Loader'
function formatSiteName(name) {
    return name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase().split(' ').join('').trim()
}


export default function KYC() {
    const [isLoading, setIsLoading] = useState(true)
    const dispatch = useDispatch()
    const [isVat, setIsVat] = useState(false)

    const { kyc_content,
        selectedPackages,
        xtraUsers,
        xtraCompanies,
        frequency,
        submissionStatus,
        isSubmitting,
        customerList,
        siteList
    } = useSelector(state => state.packages.packageSlice)

    const org_ref = useRef()
    const org_domain = useRef()
    const vat_ref = useRef()
    const [orgName, setOrgName] = useState()
    const [domainName, setdomainName] = useState('')
    const [domainNameMatchingErrors, setdomainNameMatchingErrors] = useState(false)
    useEffect(() => {
        setIsLoading(true)
        new Execute().get({ endpoint_extension: API_CONFIG.endpoint_extensions.kyc_info }).then(resolve => {
            setIsLoading(false)
            dispatch(setKYCContent({}))
            dispatch(setCustomerList([]))
            dispatch(setSiteList([]))
            if (resolve.status === API_CONFIG.status_codes.success) {
                let content = { ...kyc_content }
                let data = resolve.data.message
                let countries = []
                let sectors = []
                let company_groups = []
                let towns = []
                data.countries.map((i, j) => { countries.push({ label: i, value: i }) })
                data.company_groups.map((i, j) => { company_groups.push({ label: i, value: i }) })
                data.sectors.map((i, j) => { sectors.push({ label: i, value: i }) })
                data.towns.map((i, j) => { towns.push({ label: i.town, value: i.town }) })
                content.country = [...kyc_content.country, ...countries]
                content.company_group = [...kyc_content.company_group, ...company_groups]
                content.sectors = [...kyc_content.sectors, ...sectors]
                content.town = [...kyc_content.town, ...towns]
                dispatch(setKYCContent(content))
                dispatch(setCustomerList(data.orgs))
                dispatch(setSiteList(data.sites))
            }
        })
    }, [])

    const onSubmit = (values) => {
        if (domainName && !domainNameMatchingErrors) {
            dispatch(setIsSubmitting(true))
            dispatch(setIsSubmitting(true))
            dispatch(setSubmissionStatus(null))
            new Execute().post({
                endpoint_extension: API_CONFIG.endpoint_extensions.register,
                body: { kyc: { ...values, xtra_users: xtraUsers, frequency: frequency, domain_name: domainName, xtra_companies: xtraCompanies, currency: 'usd' }, modules: selectedPackages }
            }).then(resolve => {
                dispatch(setIsSubmitting(false))
                console.log(resolve)
                if (resolve.status === API_CONFIG.status_codes.success && resolve.data.message.status == true) {
                    dispatch(setSubmissionStatus(API_CONFIG.status_codes.success))
                }
                else {
                    dispatch(setSubmissionStatus(API_CONFIG.status_codes.server_error))
                }
            })
        }
        else console.log('err')
    }

    const { values, touched, handleChange, handleSubmit, errors } = useFormik({
        initialValues: {
            type_of_registration: '',
            sector: '',
            organization: '',
            domain_name: '',
            email_address: '',
            country: '',
            town: '',
            address: '',
            tpin: '',
            registration_no: '',
            contact_no: '',
            is_vat_registered: '',
            vat_no: ''
        },
        validationSchema: basicSchema,
        onSubmit
    })
    useEffect(() => {
        if (!isLoading) {
            org_ref.current.addEventListener('keyup', (e) => {
                setOrgName(formatSiteName(e.target.value))
            })
            org_domain.current.addEventListener('keyup', (e) => {
                setdomainName(formatSiteName(e.target.value))
            })
            $(vat_ref.current).on('change', function () {
                setIsVat(this.checked)
            })
        }

    }, [isLoading])
    useEffect(() => { setdomainName(orgName) }, [orgName])
    useEffect(() => {
        if (domainName) {
            if (siteList?.includes(domainName || '')) {
                setdomainNameMatchingErrors(true)
            }
            else {
                setdomainNameMatchingErrors(false)
            }
        }
    }, [domainName])
    return (
        <Container className="maxmize-inner-full min-h-[600px] flex items-center justify-center">
            {
                !isLoading &&
                <Card className="mx-95 form-card w-full min-h-[600px] bg-white shadow-darkers border-gray-200 my-10">
                    <span className="font-bold text-slate-700 flex items-center justify-start">
                        <BsInfoCircle className='mr-3' size={30} />
                        Provide your KYC information below
                    </span>
                    <form onSubmit={handleSubmit} className='mt-10 flex flex-col'>
                        <Grid columns={3} gap={10}>
                            <Column>
                                <small className="text-gray-500">Type Of Registration</small>
                                <Select
                                    options={kyc_content.company_group}
                                    value={values.type_of_registration}
                                    onChange={handleChange}
                                    id="type_of_registration"
                                    placeholder='Type of registration'
                                    className="w-full h-[45px]"
                                />
                                <small className='text-red-500 mb-5'>{errors.type_of_registration}</small>
                            </Column>
                            <Column>
                                <small className="text-gray-500">Select Sector</small>
                                <Select
                                    options={kyc_content.sectors}
                                    onChange={handleChange}
                                    id="sector"
                                    value={values.sector}
                                    placeholder='Enter username'
                                    className="w-full h-[45px]"
                                />
                                <small className='text-red-500 mb-5'>{errors.sector}</small>
                            </Column>
                            <Column>
                                <small className="text-gray-500">Organization Name</small>
                                <InputField
                                    width="50%"
                                    ref={org_ref}
                                    value={values.organization}
                                    onChange={handleChange}
                                    id="organization"
                                    type="text"
                                    placeholder='Enter Organization Name'
                                    className="w-full h-[45px]"
                                />
                                <small className='text-red-500 mb-5'>{errors.organization}</small>
                            </Column>
                            <Column>
                                <small className="text-gray-500">Organization Email Address</small>
                                <InputField
                                    width="50%"
                                    value={values.email_address}
                                    onChange={handleChange}
                                    id="email_address"
                                    type="email"
                                    placeholder='Enter organization email address'
                                    className="w-full h-[45px]"
                                />
                                <small className='text-red-500 mb-5'>{errors.email_address}</small>
                            </Column>
                            <Column>
                                <small className="text-gray-500">Organization Domain Name</small>
                                <Row>
                                    <InputField
                                        width="50%"
                                        ref={org_domain}
                                        value={formatSiteName(values.domain_name ? values.domain_name : orgName || '')}
                                        onChange={handleChange}
                                        id="domain_name"
                                        type="text"
                                        placeholder='Enter Organization Domain Name'
                                        className="w-full h-[45px]"
                                        style={{
                                            width: '100%',
                                            borderTopRightRadius: 0,
                                            borderBottomRightRadius: 0
                                        }}
                                    />
                                    <div
                                        className="h-[45px] w-fulls bg-[#161741] text-white flex items-center justify-start px-5"
                                        style={{
                                            borderTopRightRadius: 6,
                                            borderBottomRightRadius: 6
                                        }}
                                    >
                                        .startapperp.com
                                    </div>
                                </Row>
                                <small className='text-red-500 mb-5'>
                                    {!domainName ? 'This field is required!' : ''}
                                    {domainNameMatchingErrors ? 'This domain name is already taken!' : ''}
                                </small>
                            </Column>
                            <Column>
                                <small className="text-gray-500">Organization Country</small>
                                <Select
                                    options={kyc_content.country}
                                    value={values.country}
                                    onChange={handleChange}
                                    id="country"
                                    placeholder='Enter Organization Country'
                                    className="w-full h-[45px]"
                                />
                                <small className='text-red-500 mb-5'>{errors.country}</small>
                            </Column>
                            <Column>
                                <small className="text-gray-500">Town</small>
                                <Select
                                    options={kyc_content.town}
                                    onChange={handleChange}
                                    id="town"
                                    value={values.town}
                                    placeholder='Enter username'
                                    className="w-full h-[45px]"
                                />
                                <small className='text-red-500 mb-5'>{errors.town}</small>
                            </Column>
                            <Column>
                                <small className="text-gray-500">Organization Address</small>
                                <InputField
                                    width="50%"
                                    value={values.address}
                                    onChange={handleChange}
                                    id="address"
                                    type="text"
                                    placeholder='Enter address'
                                    className="w-full h-[45px]"
                                />
                                <small className='text-red-500 mb-5'>{errors.address}</small>
                            </Column>
                            <Column>
                                <small className="text-gray-500">Organization TPIN</small>
                                <InputField
                                    width="50%"
                                    value={values.tpin}
                                    onChange={handleChange}
                                    id="tpin" type="text"
                                    placeholder='Enter TPIN'
                                    className="w-full h-[45px]"
                                />
                                <small className='text-red-500 mb-5'>{errors.tpin}</small>
                            </Column>
                            <Column>
                                <small className="text-gray-500">Organization Registration Number</small>
                                <InputField
                                    width="50%"
                                    value={values.registration_no}
                                    onChange={handleChange}
                                    id="registration_no"
                                    type="text"
                                    placeholder='Enter Registration Number'
                                    className="w-full h-[45px]"
                                />
                                <small className='text-red-500 mb-5'>{errors.registration_no}</small>
                            </Column>
                            <Column>
                                <small className="text-gray-500">Organization Contact Number</small>
                                <InputField
                                    width="50%"
                                    value={values.contact_no}
                                    onChange={handleChange}
                                    id="contact_no" type="text"
                                    placeholder='Enter Contact No'
                                    className="w-full h-[45px]"
                                />
                                <small className='text-red-500 mb-5'>{errors.contact_no}</small>
                            </Column>
                            <Column>
                                <small className="text-gray-500">Is your Organization VAT Registered?</small>
                                <Row justify="start">
                                    <InputField
                                        ref={vat_ref}
                                        type="checkbox"
                                        value={values.is_vat_registered}
                                        onChange={handleChange}
                                        id="is_vat_registered"
                                        className="h-[45px]  mt-2"
                                        width={'38px'}
                                        wrapperClass="w-[20%] mr-1"
                                    />
                                    {
                                        isVat && <InputField
                                            width="50%"
                                            value={values.vat_no}
                                            onChange={handleChange}
                                            id="vat_no"
                                            type="text"
                                            placeholder='Enter VAT number'
                                            className="w-full h-[45px]"
                                            wrapperClass="w-[90%]"
                                        />
                                    }

                                </Row>
                                <small className='text-red-500 mb-5'>{errors.vat_no}</small>
                            </Column>
                            <Column className="muuted-grid"></Column>
                            <Column className="muuted-grid"></Column>
                            <Grid className="form-btns" gap={10}>
                                <Button onClick={() => dispatch(setCurrentPricingStage(1))} className="w-full mt-7 r-10 bg-gray-100" type="submit" style={{ height: 45, fontSize: 15 }}>Back</Button>
                                <Button className="w-full mt-7 r-10 bg-[#161741] text-white hover:bg-blue-800 " type="submit" style={{ height: 45, fontSize: 15 }}>
                                    Register
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Card>
            }
            {isLoading && <div className='w-full min-h-[600px] flex items-center justify-center'><Loader size={40} /></div>}
            {
                isSubmitting || submissionStatus != null ? <SubmissionLoader /> : ''
            }
        </Container>
    )
}
