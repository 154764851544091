import React, { useEffect, useState } from 'react'
import Card from 'components/shared/Card'
import Container from 'components/shared/Container'
import Title from 'components/shared/Title'
import Row from 'components/shared/Row'
import Button from 'components/shared/Button'
import { useDispatch } from 'react-redux'
import { setCurrentPricingStage } from 'store/packages/packageSlice'
import { API_CONFIG } from 'api/config'
import Execute from 'api/axios/Execute'
import { parseHTML } from 'jquery'
import LicenseComponent from './LicenseComponent'
import Loader from 'views/common/Loader'
import { Unique } from 'functions/common'
export default function License() {
    const [license, setLicense] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const dispatch = useDispatch()
    useEffect(() => {
        setIsLoading(true)
        new Execute().get({ endpoint_extension: API_CONFIG.endpoint_extensions.license }).then(resolve => {
            setIsLoading(false)
            if (resolve.status === API_CONFIG.status_codes.success) {
                setLicense(resolve?.data?.message)
            }
        })
    }, [])
    return (
        <Container className="license-wrapper min-h-[60vh] flex flex-col items-center justify-center my-3">
            {
                !isLoading &&
                <>
                    <Row className="license-title-wrapper w-[100%] pb-5" style={{ borderBottom: '1px #ccc solid' }} justify="between">
                        <Title className="text-[#161741]" title="License Agreement" />
                        <Row justify="end" className=" w-[80%] license-title-btns mt-3">
                            <Button onClick={() => dispatch(setCurrentPricingStage(1))} className=" w-[200px] mr-5">Disagree</Button>
                            <Button onClick={() => dispatch(setCurrentPricingStage(3))} className="bg-[#161741] hover:bg-blue-800 text-white w-[200px]">Agree</Button>
                        </Row>
                    </Row>
                    <Card className="license-content min-h-[70vh] w-[100%] shadow-darker mt-3">

                        {license?.length > 0 && license.map(l => <LicenseComponent key={Unique()} l={l} />)}
                    </Card>
                    <Row justify="end" className=" w-[80%] license-title-btn-smobile my-5 hidden">
                        <Button onClick={() => dispatch(setCurrentPricingStage(1))} className=" w-[200px] mr-5">Disagree</Button>
                        <Button onClick={() => dispatch(setCurrentPricingStage(3))} className="bg-[#161741] hover:bg-blue-800 text-white w-[200px]">Agree</Button>
                    </Row>
                </>
            }
            {
                isLoading && <Loader size={40} />
            }
        </Container>
    )
}
