import Grid from 'components/shared/Grid'
import React from 'react'
import Step from './Step'

export default function Steps() {
    return (
        <div className='steps-wrapper w-full h-[40px]'>
            <Grid columns={3} className="steps-wrapper-grid">
                <Step index={1} description="Module Selection" />
                <Step index={2} description="License Agreement" />
                <Step index={3} description="KYC" />
            </Grid>
        </div>
    )
}
