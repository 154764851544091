import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentPricingStage } from 'store/packages/packageSlice'

export default function Step({ index, description }) {
    const dispatch = useDispatch()
    const { currentPricingStage } = useSelector(state => state.packages.packageSlice)
    const active = currentPricingStage >= index
    const { selectedPackages } = useSelector(state => state.packages.packageSlice)
    const HandleClick = () => {
        if (index < 3) {
            if (index == 2) {
                let has_selected = false
                Object.keys(selectedPackages).map(key => {
                    if (selectedPackages[key]?.modules?.length > 0)
                        has_selected = true
                })
                if (has_selected)
                    dispatch(setCurrentPricingStage(index))
                else
                    alert("Atleast select one Package before proceeding to the next stage..")
            }
            else
                dispatch(setCurrentPricingStage(index))

        }
    }
    return (
        <div onClick={HandleClick} className={` ${active ? 'active' : ''} cursor-pointer hover:bg-gray-500 w-full h-[40px] p-step-clip-${index} p-step flex items-center justify-center`}>
            <span className='index  flex items-center justify-center h-[20px] w-[20px]  rounded-full mr-2'>{index}</span>
            <small className='font-bold step-description'>
                {description}
            </small>
        </div>
    )
}
