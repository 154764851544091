import React, { useEffect, useState } from 'react'
import Amount from 'components/shared/Amount'
import Button from 'components/shared/Button'
import Card from 'components/shared/Card'
import InputField from 'components/shared/InputField'
import Row from 'components/shared/Row'
import SubTitle from 'components/shared/SubTitle'
import Title from 'components/shared/Title'
import { FaArrowRight } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { CalculatePricingTotals, ConvertCurrency, FormatAmount } from 'functions/common'
import { setCurrentPricingStage, setFrequency, setFrequencyMonths, setXtraCompanies, setXtraUsers } from 'store/packages/packageSlice'
import Toggle from 'components/shared/Toggle'
export default function SummaryInfo() {
    const dispatch = useDispatch()
    const [data, setData] = useState({
        xclusive: 0,
        total: 0,
        discount: 0,
        vat: 0,
        xtraUsers: 0,
        xtraUserAmount: 0,
        xtraCompanies: 0,
        xtraCompanyAmount: 0
    })
    const {
        frequency,
        frequencyMonths,
        selectedPackages,
        commonSettings,
    } = useSelector(state => state.packages.packageSlice)
    const onIncDec = (type, value, for_) => {
        console.log(for_)
        let val = 0
        let xu = parseInt(data.xtraUsers)
        let xc = parseInt(data.xtraCompanies)
        if (type) {
            if (type === '+') {
                if (for_ === 'user')
                    if (xu == 0) val = 1
                    else val = xu + 1
                else
                    if (xc == 0) val = 1
                    else val = xc + 1
            }
            else {
                if (for_ === 'user')
                    if (xu > 0) val = xu - 1
                    else val = 0
                else
                    if (xc > 0) val = xc - 1
                    else val = 0
            }
        }
        else {
            if (value > 0) val = value
            else val = 0
        }
        let newData = { ...data }
        for_ === 'user' ? newData.xtraUsers = parseInt(val) : newData.xtraCompanies = parseInt(val)


        const calculated = CalculatePricingTotals({
            common_settings: commonSettings,
            state: newData,
            frequency: frequency,
            frequencyMonths: frequencyMonths,
            selectedPackages: selectedPackages
        })
        setData(calculated)
    }
    useEffect(() => {
        const calculated = CalculatePricingTotals({
            common_settings: commonSettings,
            state: data,
            frequency: frequency,
            frequencyMonths: frequencyMonths,
            selectedPackages: selectedPackages
        })
        setData(calculated)
    }, [selectedPackages, frequencyMonths])

    useEffect(() => {
        dispatch(setFrequencyMonths(frequency == 'Monthly' ? 1 : 12))
    }, [frequency])
    useEffect(() => {
        dispatch(setXtraUsers(data.xtraUsers))
        dispatch(setXtraCompanies(data.xtraCompanies))
    }, [data])
    const onNext = () => {
        let isSelected = false
        Object.keys(selectedPackages).map(key => {
            console.log(selectedPackages[key])
            if (key?.toLocaleLowerCase() !== 'others') {
                if (selectedPackages[key]?.modules?.length > 0)
                    isSelected = true
            }
            else {
                if (Object.keys(selectedPackages[key])?.length > 0) {
                    isSelected = true
                }
            }
        })
        if (isSelected) {
            dispatch(setCurrentPricingStage(2))
        }
        else {
            alert("Atleast select one Package before proceeding to the next stage..")
        }
    }
    return (
        <Row className="unfold totals-card flex-col center" direction="column">
            <Title
                title="Totals"
                className="text-gray-700"
                wrapperClassName="my-3 w-full maxmize-inner"
            />
            <Card className="bg-white shadow-dark maxmize-inner">
                <Row className="pb-3" style={{ borderBottom: '1px #EEEDF3 solid' }} justify="between">
                    <div className='trial-wrapper flex items-center justify-start relative shadow-darker'>
                        <h3 className='font-bold w-full text-white absolute'>{commonSettings.free_trial_days} Days Free Trial</h3>
                    </div>
                    <Toggle
                        className="my-5 bg-[#161741] p-1 shadow-darker"
                        height={30}
                        width={140}
                        offText={
                            <small className='text-[12px]'>Monthly</small>
                        }
                        onText={
                            <small className='text-[12px]'>Annually</small>
                        }
                        value={true}
                        toggleClassName="shadow-darker bg-white"
                        onToggle={status => dispatch(setFrequency(status ? 'Annually' : 'Monthly'))}
                    />
                </Row>
                <SubTitle
                    className="ml-3 font-semibold"
                    title={`${ConvertCurrency(parseFloat(commonSettings.price_per_user * frequencyMonths), commonSettings.exchange_rate).formated}  Per User`} />
                <Row justify="between" className="mt-3 pb-3 px-3" style={{ borderBottom: '1px #EEEDF3 solid' }}>
                    <Button onClick={() => onIncDec('-', 0, 'user')} className="w-[60px] font-bold text-lg">-</Button>
                    <InputField
                        onChange={(e) => onIncDec('', e.target.value, 'user')}
                        value={data.xtraUsers} type="number"
                        placeholder="Add Extra Users"
                        className="py-2 text-center"
                        wrapperClass="w-[60%] min-w-[70px]"
                    />
                    <Button onClick={() => onIncDec('+', 0, 'user')} className="w-[60px] font-bold text-lg">+</Button>
                </Row>
                <SubTitle
                    className="ml-3 font-semibold"
                    title={`${ConvertCurrency(parseFloat(commonSettings.price_per_extra_company * frequencyMonths), commonSettings.exchange_rate).formated}  Per Company`} />
                <Row justify="between" className="mt-3 pb-4 px-3" style={{ borderBottom: '1px #EEEDF3 solid' }}>
                    <Button
                        onClick={() => onIncDec('-', 0, 'company')}
                        className="w-[60px] font-bold text-lg"
                    >-</Button>
                    <InputField
                        onChange={(e) => onIncDec('', e.target.value, 'company')}
                        value={data.xtraCompanies} type="number"
                        placeholder="Add Extra Companies"
                        className="py-2 text-center"
                        wrapperClass="w-[60%] min-w-[70px]"
                    />
                    <Button onClick={() => onIncDec('+', 0, 'company')} className="w-[60px] font-bold text-lg">+</Button>
                </Row>
                <Row justify="between" className="pb-3 px-3 flex-col" style={{ borderBottom: '1px #EEEDF3 solid' }}>
                    <Row justify="between">
                        <SubTitle title="Free Users" />
                        <span className='font-bold text-lg text-gray-800'>{commonSettings.total_number_of_free_users}</span>
                    </Row>
                    <Row justify="between">
                        <SubTitle title={`Additional Users (${data.xtraUsers})`} />
                        {ConvertCurrency(data.xtraUserAmount, commonSettings.exchange_rate).formated}
                    </Row>
                    <Row justify="between">
                        <SubTitle title={`Additional Companies (${data.xtraCompanies})`} />
                        {ConvertCurrency(data.xtraCompanyAmount, commonSettings.exchange_rate).formated}
                    </Row>
                </Row>
                <Row justify="between" className="mt-3 pb-4 px-3 flex-col" style={{ borderBottom: '1px #EEEDF3 solid' }}>
                    <Row justify="between">
                        <SubTitle title="Exclusive Total" />
                        {ConvertCurrency(data.xclusive, commonSettings.exchange_rate, 2).formated}
                    </Row>
                    <Row justify="between">
                        <SubTitle title="Discount" />
                        {ConvertCurrency(data.discount, commonSettings.exchange_rate, 2).formated}
                    </Row>
                    {
                        commonSettings.apply_vat == 1 &&
                        <Row justify="between">
                            <SubTitle title="VAT" />
                            {ConvertCurrency(data.vat, commonSettings.exchange_rate, 2).formated}
                        </Row>
                    }
                    <Row justify="between" minHeight="14px" style={{ borderTop: '1px #EEEDF3 solid' }}>
                        <SubTitle title="Total Amount" />
                        {ConvertCurrency(data.total, commonSettings.exchange_rate, 2).formated}
                    </Row>
                </Row>
                <Row justify="end" className="mt-3 px-3 my-3">
                    <Button onClick={() => onNext()} className="w-[140px] bg-[#161741] hover:bg-blue-800 text-white">Proceed <FaArrowRight size={15} className="ml-1" /></Button>
                </Row>
            </Card>
        </Row>
    )
}
