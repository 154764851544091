import Spinner from 'components/loaders/spinner'
import React from 'react'

export default function Loader({ text, size, borderColor }) {
    return (
        <div className='w-full h-full flex items-center justify-center flex-col'>
            <Spinner size={size || 30} borderColor={borderColor || '#161741'} />
            {text || 'Loading...'}
        </div>
    )
}
