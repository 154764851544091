import Container from 'components/shared/Container'
import Flex from 'components/shared/Flex'
import Grid from 'components/shared/Grid'
import Title from 'components/shared/Title'
import { Unique } from 'functions/common'
import React from 'react'
import { useSelector } from 'react-redux'
import Feature from './Feature'

export default function FeatureList() {
    const { featureList } = useSelector(state => state.feature)
    return (
        <Container>
            <Flex direction="column" className="mt-lg" style={{ marginTop: 40 }} >
                <Title
                    wrapperClassName="items-center justify-start mt-3"
                    title="Features available on StartApp ERP"
                    className="text-[#161741] font-bold text-left"
                    subClass="text-[#161741] my-5 text-left"
                    subTitle="Some of the features available listed below"
                    style={{ fontSize: 40 }}
                />
                <Grid className="mt-10" gap={40}>
                    {
                        featureList?.length > 0 &&
                        featureList.map(feature => <Feature key={Unique()} data={feature} />)
                    }
                </Grid>
            </Flex>
        </Container>
    )
}
