import Title from 'components/shared/Title'
import React from 'react'
import parse from 'html-react-parser'
export default function LicenseComponent({ l }) {
    return (
        <div className='w-full'>
            <Title title={l.title} className="my-5 text-slate-700" style={{ fontSize: 15 }} />
            {
                <div style={{ fontSize: 12 }}>
                    {parse(l.license_content)}
                </div>
            }
        </div>
    )
}
