import Card from 'components/shared/Card'
import 'css/footer.css'
import Column from 'components/shared/Column'
import Container from 'components/shared/Container'
import Grid from 'components/shared/Grid'
import Image from 'components/shared/Image'
import Link from 'components/shared/Link'
import Row from 'components/shared/Row'
import Logo from 'components/template/Logo'
import { IMG_PATH } from 'constants'
import React from 'react'
import { FaEnvelope, FaFacebook, FaLink, FaLinkedin, FaPhoneAlt, FaTwitter } from 'react-icons/fa'

export default function Footer() {
    return (
        <Container className="bg-[#161741] pb-1 my-0">
            <Grid columns={3} gapX={10} className="text-white">
                <Card className="border-0">
                    <Column>
                        <Logo width={100} />
                        <small>
                            To find out more about StartApp. below are the links to our social media apps
                        </small>
                        <Row>
                            <Link className="ml-0" to=''><FaFacebook size={20} /></Link>
                            <Link to=''><FaTwitter size={20} /></Link>
                            <Link to=''><FaLinkedin size={20} /></Link>
                        </Row>
                    </Column>
                </Card>
                <Card className="border-0">
                    <h5 className='font-bold'>Contacts</h5>
                    <small>ZCCM-IH Office Park, Mass Media Area, Alick Nkhata Road, Lusaka Lusaka ZM, 10110</small>
                    <Row className="">
                        <FaPhoneAlt size={14} />
                        <small className='ml-2'>+260 97 6360360</small>
                    </Row>
                    <Row>
                        <FaEnvelope />
                        <span className="ml-2">usersupport@probasegroup.com</span>
                    </Row>
                </Card>
                <Card className="footer-links border-0 flex items-center justify-end">
                    <Column className="w-[70%]">
                        <h5 className='font-bold'>Quick Links</h5>
                        <Link className="ml-0 my-3 text-sm" to="/home"  ><FaLink className='mr-2' /> Home</Link>
                        <Link className="ml-0 mb-3 text-sm" to="/pricing"  ><FaLink className='mr-2' /> Get Started</Link>
                        <Link className="ml-0 mb-3 text-sm" to="/pricing"  ><FaLink className='mr-2' /> Pricing</Link>
                        <Link className="ml-0 mb-3 text-sm" to="/features"  ><FaLink className='mr-2' /> Features</Link>
                        <Link className="ml-0 mb-3 text-sm" to="/about"  ><FaLink className='mr-2' /> About</Link>
                    </Column>
                </Card>
            </Grid>
            <Grid className="powered" style={{ borderTop: '1px white solid' }} columns={2}>
                <Card className="border-0 p-0 text-white">
                    <Row className="text-sm">
                        &copy;{new Date().getFullYear()} | All Rights Reserved | <Link>Powered By: <Image resizeMethod="contain" size={60} path={`${IMG_PATH}/probase.png`} /></Link>
                    </Row>
                </Card>
                <Card className="terms p-0 border-0 text-white flex items-center justify-end">
                    <Link className='text-sm'>Terms Of Use | Privacy Policy</Link>
                </Card>
            </Grid>
        </Container>
    )
}
