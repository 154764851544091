import Container from 'components/shared/Container'
import React, { useEffect } from 'react'
import Head from './components/Head'
import 'css/features.css'
import FeatureList from './components/FeatureList'
import Footer from 'views/common/Footer'
import Execute from 'api/axios/Execute'
import { API_CONFIG } from 'api/config'
import { useDispatch } from 'react-redux'
import { setFeatureList } from 'store/features/featureSlice'
export default function Features() {
    const dispatch = useDispatch()
    useEffect(() => {
        new Execute().get({ endpoint_extension: '.get_module_featues' }).then(resolve => {
            if (resolve.status === API_CONFIG.status_codes.success) {
                dispatch(setFeatureList(resolve.data.message))
            }
        });
    }, [])
    return (
        <>
            <Container className="w-full my-0 mb-10">
                <Head />
                <FeatureList />
            </Container>
            <Footer />
        </>
    )
}
