/**
 * To add your routes to the unauthenticated route list, follow the example below,
 * 1. import the component from your views/pages table
 * 2. create an object inside the defaults array and give it the values provided in the example below.
 * to test this, uncomment both 'import Home from "../../views/home";' and the object inside the array
 */

// import Home from "../../views/home";
import About from "views/about/About"
import Documents from "views/documents"
import Features from "views/features"
import Home from "views/home/Home"
import Pricing from "views/pricing"
export default [
    {
        element: <Home />,
        path: '/home',
        title: 'Home',
        isRoot: true,
        icon: '',
        roles: [],
        items: []
    },
    {
        element: <Features />,
        path: '/features',
        title: 'Features',
        isRoot: false,
        icon: '',
        roles: [],
        items: []
    },
    {
        element: <Pricing />,
        path: '/pricing',
        title: 'Pricing',
        isRoot: false,
        icon: '',
        roles: [],
        items: []
    },
    {
        element: <About />,
        path: '/about',
        title: 'About',
        isRoot: false,
        icon: '',
        roles: [],
        items: []
    },
    {
        element: <Documents />,
        path: '/documents',
        title: 'Documents',
        isRoot: false,
        icon: '',
        roles: [],
        items: []
    },

]