import Button from 'components/shared/Button'
import Logo from 'components/template/Logo'
import React, { useEffect, useState } from 'react'
import 'css/navbar.css'
import Link from 'components/shared/Link'
import { useLocation, useNavigate } from 'react-router-dom'
import $ from 'jquery'
import MobileToggler from './MobileToggler'
import Action from 'components/shared/Action'
export default function NavBar() {
    const navigate = useNavigate()
    const location = useLocation()
    const [scrolled, setScrolled] = useState()
    const [isToggled, setIsToggled] = useState(false)
    useEffect(() => {
        setIsToggled(false)
        $('body').removeClass('freeze')
        if (location.pathname.toLowerCase() !== '/home') {
            $('.navbar').css({
                background: '#161741',
                position: 'relative'
            })
        }
        else {
            $('.navbar').css({
                background: 'transparent',
                position: 'fixed'
            })
        }
    }, [location])
    useEffect(() => {
        window.addEventListener('scroll', (e) => {
            if (window.scrollY > 100) {
                setScrolled(true)
            }
            else {
                setScrolled(false)
            }
        })
    }, [])

    const toggleMenu = () => {
        setIsToggled(!isToggled)
    }
    useEffect(() => {
        isToggled ? $('body').addClass('freeze') : $('body').removeClass('freeze')
    }, [isToggled])
    const goTo = (to) => {
        // $('body').removeClass('freeze')
        // navigate(to)
        setIsToggled(false)
    }
    return (
        <div className={`navbar ${scrolled ? 'scrolled' : ''} w-full h-[80px] bg-[] w-full flex -center-center justify-center relative`}>
            <div className='maxmize-inner w-4/5 h-full flex items-center justify-between '>
                <Link className="m-0" to="/home">
                    <Logo style={{ width: 110 }} />
                </Link>
                <div className='nav-links flex items-center justify-between'>
                    <Link className="mr-7" title="Homepage" to="/home" />
                    <Link className="mr-7" title="Features" to="/features" />
                    <Link className="mr-7" title="Pricing" to="/pricing" />
                    <Link className="mr-7" title="About" to="/about" />
                    <Link className="mr-7" title="Documents" to="/documents" />
                </div>
                <div className='nav-btns flex items-center justify-center'>
                    <Button
                        className=" w-[150px] h-[40px] text-gray-800 font-bold"
                        onClick={() => navigate('/pricing')}
                    >Sign Up</Button>
                </div>
                <div className='mobile-btns hidden items-center justify-center'>
                    <Button onClick={toggleMenu} className="mobile-nav-toggler">{!isToggled ? 'Menu' : 'Close'}</Button>
                </div>
            </div>
            <MobileToggler isToggled={isToggled} />
        </div>
    )
}
