import Card from 'components/shared/Card'
import Flex from 'components/shared/Flex'
import Image from 'components/shared/Image'
import { IMG_PATH } from 'constants'
import React from 'react'

export default function WhyCard({ className, style, data }) {
    return (
        <Card className={`shadow-darker border-0 ${className}`} style={style}>
            <Flex direction="column" >
                <Image path={`${IMG_PATH}${data.icon}`} size={80} className="mb-5" />
                <h6 className='font-bold text-lg mb-1'>{data.title}</h6>
                <p className='text-xs text-gray-700 leading-loose'> {data.content}</p>
            </Flex>
        </Card>
    )
}
