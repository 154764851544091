import Card from 'components/shared/Card'
import Container from 'components/shared/Container'
import Grid from 'components/shared/Grid'
import Image from 'components/shared/Image'
import Title from 'components/shared/Title'
import { IMG_PATH } from 'constants'
import React from 'react'

export default function Head() {
    return (
        <div className="feature-head w-full flex items-center justify-start min-h-[580px]">
            <Card className="deco-card absolute border-0 top-0" radius={0} />
            <Container className="mx-95">
                <Grid columns={2}>
                    <Card className="border-0 flex items-center justify-center relative">
                        <Title
                            wrapperClassName="items-start justify-start mt-3"
                            title="StartApp ERP Features"
                            className="features-title text-white font-bold text-left"
                            subClass="text-white my-5 text-left"
                            subTitle="From improved productivity, increased efficiency and reduced costs, below are are some of the features intergrated into StartApp ERP."
                            style={{ fontSize: 50 }}
                        />
                    </Card>
                    <Card className="border-0 relative">
                        <div className="background-deco absolute"></div>
                        <Image className="feature-image" width='155%' height='100%' resizeMethod="contain" path={`${IMG_PATH}mailpc.png`} />
                    </Card>
                </Grid>
            </Container>
        </div>
    )
}
