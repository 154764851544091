import React from 'react'
import Footer from 'views/common/Footer'
import '../../css/home.css'
import Benefits from './components/Benefits'
import Head from './components/Head'
import Welcome from './components/Welcome'
import WhyUseStartapperp from './components/WhyUseStartapperp'
export default function Home() {
    return (
        <div className='w-full'>
            <Head />
            <Welcome />
            <WhyUseStartapperp />
            <Benefits />
            <Footer />
        </div>
    )
}
