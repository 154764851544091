import PageControlsTab from 'components/shared/PageControlsTab'
import Title from 'components/shared/Title'
import React, { useEffect, useState } from 'react'
import Document from './components/Document'
import { Unique } from 'functions/common'
import Execute from 'api/axios/Execute'
import { API_CONFIG } from 'api/config'
import Loader from 'views/common/Loader'
export default function Documents() {
    const [docs, setDocs] = useState([])
    const [loading, setLoading] = useState(true)
    const [foundContent, setFoundContent] = useState(true)

    useEffect(() => {
        setLoading(true)
        new Execute().get({
            endpoint_extension: API_CONFIG.endpoint_extensions.documents
        }).then(resolve => {
            setLoading(false)
            if (resolve.status === API_CONFIG.status_codes.success && resolve?.data?.message?.data?.length > 0) {
                setFoundContent(true)
                setDocs(resolve?.data?.message?.data)
            }
            else {
                setFoundContent(false)
            }
        }).catch(error => {
            setLoading(false)
            setFoundContent(false)
        })
    }, [])
    return (
        <>
            <div className="w-full bg-defaultblue">
                <div className="h-[20px]"></div>
                <div className="h-[100%] w-full absolute top-0 left-0 z-[0] opacity-[0.2]">
                    <img src='img/others/sv.svg' className='w-full h-full object-contain' />
                </div>
                <div className='w-[100%] mx-auto min-h-[90vh] bg-white pt-10 rounded-t-[20px]'>
                    <div className='w-[80%] mx-auto docs-wrapper'>
                        <PageControlsTab className="border-b-1 border-defaultblue pb-10">
                            <Title
                                title="StartApp ERP Documents"
                                wrapperClassName="flex items-center justify-center mx-auto"
                                className="text-slate-800 font-bold text-slate-600 text-center text-[35px]"
                                subClass="mt-5 w-[80%] text-center"
                                subTitle="Download the documents related to StartApp ERP.  Documents such as brochures, event content, Company Profile, documentation content and other like documents"
                                style={{ fontSize: 40 }}
                            />
                        </PageControlsTab>
                        {
                            !loading && (
                                <div className={`docs-wrapper mx-auto ${docs?.length < 3 ? 'flex items-center justify-center max-w-[400px] gap-10' : 'w-[100%] grid grid-cols-3 gap-[100px]'}`}>
                                    {
                                        docs?.length > 0 && docs.map(doc => <Document key={Unique()} data={doc} />)
                                    }

                                </div>
                            )
                        }
                        {
                            loading && (
                                <div className='w-full minh-h-[500px] flex items-center justify-center flex-col'>
                                    <Loader text="Fetching Documents..." size={30} />
                                </div>
                            )
                        }
                        {
                            !foundContent && (
                                <div className='w-full minh-h-[500px] flex items-center justify-center flex-col'>
                                    <h3 className='my-10' style={{ fontSize: 50 }}>Opps!</h3>
                                    <p className='text-slate-600'>
                                        Seems no documents have been uploaded!
                                    </p>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div >

        </>
    )
}
