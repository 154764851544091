export function Unique(length = 30) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const FormatAmount = (amount, decimals = 2, currency = 'ZMW') => {
    return new Intl.NumberFormat(currency, {
        style: 'currency',
        currency: currency,
        maximumFractionDigits: decimals
    }).format(amount)
}

export const IsObjectEmpty = (obj) => {
    if (obj !== null)
        return Object.keys(obj).length === 0
    return true
}
export const CalculatePricingTotals = (data) => {
    const cs = data.common_settings
    const selected = data.selectedPackages
    const state = data.state
    let totalModuleAmount = 0
    let discount_on_modules = 0
    let discount_on_users = 0
    let overrall_total = 0
    let vat = 0
    let xclusive = 0
    let discount = 0
    let xtraUserAmount = (state.xtraUsers * parseFloat(cs.price_per_user)) * data.frequencyMonths
    let xtraCompanyAmount = (state.xtraCompanies * parseFloat(cs.price_per_extra_company)) * data.frequencyMonths
    Object.keys(selected).map(module => {
        if (module?.toLocaleLowerCase() !== 'others') {
            if (!IsObjectEmpty(selected[module]))
                totalModuleAmount += parseFloat(selected[module].package.price)
        }
        else {
            const keys = Object.keys(selected[module])
            keys?.length > 0 && keys.map(key => {
                totalModuleAmount += parseFloat(selected[module][key]?.package?.price || 0)
            })
        }

    })
    totalModuleAmount = totalModuleAmount * data.frequencyMonths
    xclusive = (xtraUserAmount + totalModuleAmount + xtraCompanyAmount)
    if (data.frequency == 'Annually') {
        if (cs.apply_discount_on_first_year == 1)
            discount_on_modules = (totalModuleAmount * parseFloat(cs.discount)) / 100
        if (cs.apply_discount_on_users == 1)
            discount_on_users = (xtraUserAmount * parseFloat(cs.discount)) / 100
    }
    discount = discount_on_modules + discount_on_users
    overrall_total = (xtraUserAmount + totalModuleAmount + xtraCompanyAmount) - discount
    if (cs.apply_vat == 1) {
        vat = (overrall_total * parseFloat(cs.vat_rate)) / 100
    }
    overrall_total += vat
    return {
        xclusive: xclusive,
        total: overrall_total,
        vat: vat,
        xtraUserAmount: xtraUserAmount,
        xtraUsers: state.xtraUsers,
        xtraCompanies: state.xtraCompanies,
        xtraCompanyAmount: xtraCompanyAmount,
        discount: discount
    }

}

export const ConvertCurrency = (amount, rate, decimals = 0) => {
    const converted = amount / rate
    const price = {
        amount: converted,
        formated: `$${converted.toFixed(0)}`
        // FormatAmount(converted, decimals, 'USD')
    }
    return price
}