import React from 'react'
import NavBar from 'views/common/NavBar'

export default function EmptyLayout(props) {
    return (
        <div className='w-full h-m-[100vh]'>
            <NavBar />
            {props.children}
        </div>
    )
}
