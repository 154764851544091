import Amount from 'components/shared/Amount'
import Card from 'components/shared/Card'
import CheckField from 'components/shared/CheckField'
import Image from 'components/shared/Image'
import Row from 'components/shared/Row'
import Title from 'components/shared/Title'
import { IMG_PATH } from 'constants'
import { ConvertCurrency, FormatAmount, Unique } from 'functions/common'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BsChevronDown } from 'react-icons/bs'
import Action from 'components/shared/Action'
import { setSelectedPackageList } from 'store/packages/packageSlice'
export default function Package(props) {
    const dispatch = useDispatch()
    const [collaps, setCollaps] = useState(false)
    const toggleCollaps = () => { setCollaps(!collaps) }
    const { className, data } = props
    const { frequency, selectedPackages, frequencyMonths, commonSettings } = useSelector(state => state.packages.packageSlice)
    const refs = useRef([])
    const onCheck = (e, pack) => {
        let newSelected = { ...selectedPackages }
        refs.current.map(ref => {
            if (ref.getAttribute('packagegroup')?.toLowerCase()?.trim() !== 'others') {
                if (ref !== e.target) ref.checked = false
                else {
                    const group = e.target.getAttribute('packagegroup')
                    if (ref.checked) newSelected[group] = pack
                    else newSelected[group] = null
                }
            }
            else {
                if (ref === e.target) {
                    const group = e.target.getAttribute('packagegroup')
                    let new_val = { ...newSelected[group] }
                    if (ref.checked) {
                        new_val[pack.package.name] = pack
                        newSelected[group] = new_val
                        e.checked = true
                    }
                    else {
                        let obj = { ...newSelected[group] }
                        Reflect.deleteProperty(obj, ref.getAttribute('package'))
                        newSelected[group] = obj
                    }
                }
            }
        })
        dispatch(setSelectedPackageList(newSelected))
    }
    useEffect(() => {
        Object.keys(selectedPackages).map(pkg => {
            const pk = selectedPackages[pkg]
            if (pk !== null && Object.keys(pk).length > 0) {
                refs?.current?.map(ref => {
                    if (ref.getAttribute('packagegroup')?.toLowerCase()?.trim() !== 'others') {
                        if (pk?.package?.name === ref?.getAttribute('package')) {
                            ref.checked = true
                        }
                    }
                    else {
                        const keys = Object.keys(selectedPackages[pkg])
                        keys?.map(key => {
                            if (ref?.getAttribute('package') === key) {
                                ref.checked = true
                            }
                        })
                    }
                })
            }
        })
    }, [selectedPackages, collaps, toggleCollaps, frequencyMonths])


    return (
        <Card
            className={`unfold border-0 shadow-dark rounded flex items-center justify-start flex-col border package-wrapper ${className} ${collaps ? 'collapsed' : ''}`}
            style={{ border: '1px #EEEDF3 solid' }}
            radius={5}
        >
            <Row onClick={() => toggleCollaps()} className="cursor-pointer h-[40px] mb-2 w-[100%]" justify="between" style={{ borderBottom: '1px #EEEDF3 solid' }}>
                <Row>
                    <Image path={`${IMG_PATH}${data.icon}.png`} className="mr-2 object-contain" size={30} />
                    <Title className="package-title" title={data.group} style={{ fontSize: 17 }} />
                </Row>
                <Action onClick={() => toggleCollaps()}>
                    <BsChevronDown size={15} className={`collaps-arrow ${!collaps ? 'arrow-collaps' : ''}`} />
                </Action>
            </Row>
            {
                data.module_pricing.length > 0 &&
                data.module_pricing.map(mp =>
                    mp.packages.map((pack, index) =>
                        <Row key={Unique()} justify="between" className=" ml-5 h-[45px]" style={{ borderBottom: '1px #EEEDF3 solid' }}>
                            <Row align="start">
                                <CheckField
                                    key={Unique(30)}
                                    size={17}
                                    packagegroup={data.group}
                                    ref={el => { el && refs.current.push(el) }}
                                    className="package-selector"
                                    onChange={e => onCheck(e, pack)}
                                    id={refs.current.length + 1}
                                    package={pack.package.name}
                                />
                                <span className='text-sm text-gray-800' style={{ fontSize: 13 }}>{pack.package.name}</span>
                            </Row>
                            <span className="p-r-10 text-[12px]">
                                {ConvertCurrency(parseFloat(pack.package.price) * frequencyMonths, commonSettings.exchange_rate)?.formated}
                            </span>
                        </Row>
                    )
                )
            }

        </Card>
    )
}
