import Image from 'components/shared/Image'
import { IMG_PATH } from 'constants'
import React from 'react'
import { FiDownload } from 'react-icons/fi'
import '../../../css/docs.css'
import { GetBaseEndpoint } from 'api/config'
export default function Document({ data }) {
    const base_endpoint = GetBaseEndpoint()
    return (
        <div
            className='doc w-[97%] h-[500px] bg-white shadow-darker rounded-md flex items-center justify-center relative'
        >
            <div className='w-[50%] h-[110%] absolute left-[-24px] bg-defaultblue rounded-md'></div>
            <a href={`${base_endpoint}${data.file_attachment}`} download={data.title} className='w-[50%] absolute right-[-15px] text-[15px] bottom-[-34px] z-[12] font-bold flex items-center justify-center text-defaultblue'>
                <FiDownload className='mr-2 font-[18px] font-bold' />
                Download
            </a>
            <div
                className='w-full h-full bg-white shadow-darker rounded-md flex items-center justify-start flex-col overflow-hidden z-[10] relative'
            >
                <Image path={`${base_endpoint}${data.thumbnail}`}
                    className="w-full h-[100%] object-cover"
                />
                <div className='doc-overlay w-full h-full absolute z-[2] flex items-center justify-end flex-col'>
                    <div className='w-[90%] h-[80%] flex items-center justify-start flex-col'>
                        <h3 className='text-white font-bold doc-title' style={{ fontSize: 30 }}>{data.title}</h3>
                        <div className='title-block relative'></div>
                        <div className='my-4 paragraph'>
                            <p className='text-white my-4'>{data?.brief_description?.substring(0, 190)}</p>
                        </div>
                        <a href={`${base_endpoint}${data.file_attachment}`} download={data.title} className='w-[100%] big-download-btn h-[50px] bg-white rounded-md right-[-15px] text-[15px] font-bold flex items-center justify-center text-defaultblue'>
                            <FiDownload className='mr-2 font-[18px] font-bold' />
                            Download
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
