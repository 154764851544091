import Container from 'components/shared/Container'
import Flex from 'components/shared/Flex'
import Grid from 'components/shared/Grid'
import Title from 'components/shared/Title'
import { benefitsContent } from 'constants/data'
import { Unique } from 'functions/common'
import React from 'react'
import BenefitsCard from './BenefitsCard'

export default function Benefits() {
    return (
        <Container minHeight={0} className="benefits-wrapper bg-[#161741] pb-10 my-0 mb-1" style={{ marginTop: 70 }}>
            <Flex direction="column">
                <Title
                    wrapperClassName="items-center justify-center mt-3"
                    title="Benefits of startApp ERP system"
                    className="benefits-title text-white font-bold"
                    subClass="text-white my-7"
                    subTitle="From improved productivity, increased efficiency and reduced costs, below are are some of the benefits of intergrating StartApp ERP into your business."
                    style={{ fontSize: 50 }}
                />
                <Grid columns={3} gap={40} className="benefits-grid">
                    {
                        benefitsContent.map(benefit => <BenefitsCard key={Unique()} data={benefit} />)
                    }
                </Grid>
            </Flex>
        </Container>
    )
}
