import Card from 'components/shared/Card'
import Container from 'components/shared/Container'
import Flex from 'components/shared/Flex'
import Image from 'components/shared/Image'
import Title from 'components/shared/Title'
import { IMG_PATH } from 'constants'
import React from 'react'

export default function Welcome() {
    return (
        <Container minHeigh={0} className="welcome-wrapper w-full min-h-[500px] relative">
            <Card radius={0} className="welcome-card bg-[#161741] h-[60%] absolute border-0 " />
            <Flex direction="column">
                <div style={{ marginTop: 70 }} className="welcome-title-wrapper">
                    <Title
                        wrapperClassName="items-center justify-center"
                        title="Welcome To StartApp"
                        className="text-white font-bold welcome-title"
                        subTitle="Join over 1,000 business owners using Startapp"
                        subClass="text-white my-5"
                        style={{ fontSize: 50 }}
                    />
                </div>
                <Image path={`${IMG_PATH}mailpc.png`} className="lg:w-[57%] xl:lg:w-[60%]  w-[80%] lg:mt-10" />
            </Flex>
        </Container>
    )
}
