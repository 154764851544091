import Container from 'components/shared/Container'
import Grid from 'components/shared/Grid'
import React, { useEffect } from 'react'
import Package from './Package'
import { useDispatch, useSelector } from 'react-redux'
import { setPackageGroups } from 'store/packages/packageSlice'
export default function PackageList() {
    const { packageList, frequencyMonths } = useSelector(state => state.packages.packageSlice)
    const dispatch = useDispatch()
    useEffect(() => {
        const packageGroups = {}
        packageList.map(pack => {
            packageGroups[pack.group] = null
        })
        dispatch(setPackageGroups((packageGroups)))
    }, [packageList, frequencyMonths])
    return (
        <Container className="mt-2 w-full mx-95 p-0">
            <span className="get-started-title font-bold text-left text-slate-700">
                Get Started By picking the modules that suit your business
            </span>
            <Grid className="package-list-grid w-full mt-2 gap-3" columns={2}>
                {
                    packageList.length > 0 &&
                    packageList?.map((pack, index) => <Package group={pack?.group} key={index} index={index} data={pack} />

                    )
                }
            </Grid>
        </Container>
    )
}
