import Spinner from 'components/loaders/spinner'
import Button from 'components/shared/Button'
import Card from 'components/shared/Card'
import Grid from 'components/shared/Grid'
import React from 'react'
import { BsCheck2Circle, BsInfoCircle } from 'react-icons/bs'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setCurrentPricingStage, setIsSubmitting, setSubmissionStatus } from 'store/packages/packageSlice'

export default function SubmissionLoader() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { submissionStatus, isSubmitting } = useSelector(state => state.packages.packageSlice)
    const handleClick = (status) => {
        if (!status) {
            dispatch(setSubmissionStatus(null))
            dispatch(setCurrentPricingStage(1))
            navigate('/home')
        }
        else {
            dispatch(setSubmissionStatus(null))
            dispatch(setIsSubmitting(false))
        }
    }
    return (
        <Card className="sl-wrapper flex items-center justify-center border-0">
            <Grid className="w-[600px] h-[280px] bg-white shadow-darker rounded-md">
                <Card className="w-full h-full border-0 rounded-0 flex items-center justify-center flex-col">
                    {
                        isSubmitting && (
                            <>
                                <Spinner />
                                <span className="submission-title">Submitting..</span>
                            </>
                        )
                    }
                    {
                        submissionStatus === 200 && (
                            <>
                                <BsCheck2Circle size={70} color="#161741" />
                                <span className="text-slate-700">Registration Complete!</span>
                            </>
                        )
                    }
                    {
                        submissionStatus === 500 && (
                            <>
                                <FaTimesCircle size={70} color="#f00" />
                                <span className="text-slate-700">Registration Failed!</span>
                            </>
                        )
                    }
                </Card>
                <Card className="w-full h-full bg-[#161741] border-0 text-white flex items-center justify-center flex-col">
                    {
                        isSubmitting && (
                            <>
                                <h5 className='font-bold'>Setting Up Your Account!</h5>
                                <span className="submission-title text-center">
                                    Please wait while your KYC is being submitted.
                                </span>
                            </>
                        )
                    }
                    {
                        submissionStatus === 200 && (
                            <>
                                <BsInfoCircle size={25} className='mb-2 text-gray-300' />
                                <span className="text-gray-300 text-center" style={{ fontSize: 15 }}>
                                    Thank you for registering with StartApp ERP. <br />
                                    Your credentials will be sent to your email when the site is setup.
                                </span>
                                <Button
                                    onClick={() => handleClick()}
                                    className="bg-white text-slate-700 w-[150px] h-[40px] mt-3 hover:bg-gray-200"
                                >Done</Button>
                            </>
                        )
                    }
                    {
                        submissionStatus === 500 && (
                            <>
                                <span className="text-gray-300 text-center" style={{ fontSize: 15 }}>
                                    An error occurred while setting up your account. Please try again.
                                </span>
                                <Button
                                    onClick={() => handleClick('failed')}
                                    className="bg-white text-slate-700 w-[150px] h-[40px] mt-3 hover:bg-gray-200"
                                >Try again</Button>
                            </>
                        )
                    }
                </Card>
            </Grid>
        </Card>
    )
}
