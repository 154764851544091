import React from 'react'

const MainAbout = () => {
    return (
        <div className='about'>
            <div className='cards-ab'>
                <h2>StartApp</h2>
                <div className='about-cards'>
                    <div className='cards-holder'>
                        <div className='card'>
                            <h3>Compliance</h3>
                            <p>
                                comply to all regulations with just a click of button, comply to NIHMA, NAPSA, ZRA Etc.
                            </p>
                        </div>
                        <div className='card'>
                            <h3>Slimplify Your business</h3>
                            <p>
                                Manage and intergrate the important parts of the business enterprise With ease and let the system stress for you.
                            </p>
                        </div>
                        <div className='card'>
                            <h3>Automation</h3>
                            <p>
                                Automate all You Daily routine, frequent tasks and Slimplify your workflow
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='info'>
                <div className="data">
                    <h2>The StartApp Story</h2>
                    <p>
                        StartApp ERP provides a cloud-based system with Accounting, Human Resource(HR) Management, Payroll Management, Customer Relationship Management(CRM), Stock Management, Project Management plus more modules all in one place. This Allows you to access overall insights about the different facets of the business from one point.
                    </p>
                    <p>
                        This solution was born from the need from customers who sought a more flexible and simplified platform for their business management. The design and development of the solution took over 3 years of data gathering and analysis from experts and consultants in the various fields that are represented on the ERP.
                    </p>
                    <p>
                        The solution was developed by ProBASE Limited. ProBASE Limited is a Financial Technology (FinTech) and Information Communication Technology (ICT) Company with it’s head office in Zambia. It has a proven track record of developing technology solutions for the public and private sectors that support collections and payments. As a top-tier technology company in the region and beyond, ProBASE Limited has provided consulting services in Zambia, Lesotho, South Africa, the Democratic Republic of the Congo (DRC) and Malawi.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default MainAbout
