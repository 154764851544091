import Execute from 'api/axios/Execute'
import { API_CONFIG } from 'api/config'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCommonSettings, setPackageList, setSelectedPackageList } from 'store/packages/packageSlice'
import PackageList from './components/PackageList'
import '../../css/pricing.css'
import Footer from 'views/common/Footer'
import KYC from './components/KYC'
import Grid from 'components/shared/Grid'
import SummaryInfo from './components/SummaryInfo'
import Container from 'components/shared/Container'
import License from './components/License'
import Card from 'components/shared/Card'
import Steps from './components/Steps'
import Loader from 'views/common/Loader'
export default function Pricing() {
    const dispatch = useDispatch()
    const { packageList, currentPricingStage } = useSelector(state => state.packages.packageSlice)
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        setIsLoading(true)
        new Execute().get({ endpoint_extension: API_CONFIG.endpoint_extensions.packages }).then(resolve => {
            setIsLoading(false)
            if (resolve.status === API_CONFIG.status_codes.success) {
                dispatch(setCommonSettings(resolve.data.message.common_settings))
                dispatch(setPackageList(resolve.data.message.modules))
            }
        })
    }, [])
    useEffect(() => {
        let newArr = {}
        if (packageList.length > 0) {
            packageList.map(pack => {
                newArr[pack.group] = []
            })
            dispatch(setSelectedPackageList(newArr))
        }
    }, [packageList])
    return (
        <div className='w-full flex flex-col items-center justify-start'>
            <div className={`maxmize-inner w-[80%] ${currentPricingStage === 3 ? 'steps-on-kyc' : ''} h-[40px] mt-10 p-0 border-0 steps-container`}>
                <Steps />
            </div>
            {
                currentPricingStage === 1 && !isLoading &&
                <Container className="maxmize-inner-full">
                    <Grid columns={3} className="my-3" gap={20}>
                        <div className="col-span-2 center">
                            <PackageList />
                        </div>
                        <SummaryInfo />
                    </Grid>
                </Container>
            }
            {currentPricingStage === 1 && isLoading && <div className='w-full min-h-[600px] flex items-center justify-center'><Loader size={40} /></div>}
            {currentPricingStage === 2 && <License />}
            {currentPricingStage === 3 && <KYC />}
            <Footer />
        </div >
    )
}
