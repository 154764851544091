import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    featureList: []
}
export const featureSlice = createSlice({
    name: 'featureSlice',
    initialState,
    reducers: {
        setFeatureList: (state, action) => {
            state.featureList = action.payload
        },
    }
})
export const {
    setFeatureList,
} = featureSlice.actions
export default featureSlice.reducer