import { combineReducers } from 'redux'
import auth from './auth'
import layout from './layout'
import packages from './packages'
import feature from './features/featureSlice'
const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        layout,
        auth,
        packages,
        feature,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}

export default rootReducer
