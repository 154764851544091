import Button from 'components/shared/Button'
import Column from 'components/shared/Column'
import Container from 'components/shared/Container'
import Grid from 'components/shared/Grid'
import Row from 'components/shared/Row'
import Title from 'components/shared/Title'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Exec from './exec'

export default function Overlay() {
    const navigate = useNavigate()
    const ref = useRef()
    let content = [
        {
            title: "Empowering Micro & Medium Enterprises in Africa.",
            content: `StartApp ERP is designed to promote financial inclusion by providing SMEs with the tools and capabilities they need to succeed.`
        },

        {
            title: `Eliminate manual processes and simplify your complexity.`,
            content: `Automate manual or routine tasks, 
            implement smarter workflows and gain efficiency`
        },
        {
            title: "StartApp is built to simplify your business complexity.",
            content: `Startapp ERP is a system used to manage 
            and intergrate the important parts of the business 
            enterprise. It is important to your business.`
        },
    ]

    useEffect(() => { new Exec(ref, content) }, [])
    return (
        <Container className="maxmize-inner flex items-center h-full">
            <Grid columns={3}>
                <Column className="col-span-2">
                    <div ref={ref} className="text-white overlay-anim-wrapper my-10 relative">
                        <div id={1} className="overlay-inner min-h-[max-content]  w-full flex mb-5">
                            <div className="overlay-title flex"></div>
                        </div>
                        <p className="overlay-content mt-6 w-3/4"></p>
                    </div>
                    <Row className="overlay-btns ">
                        <Button className="mr-2 h-[60px] w-[200px] font-bold" onClick={() => navigate('/pricing')}>Get Started</Button>
                        <Button
                            background="transparent"
                            className="bg-transparent border-white-700 h-[60px] w-[200px] text-white font-bold"
                            onClick={() => navigate('/features')}
                            style={{ borderWidth: 2 }}
                        >
                            Learn More
                        </Button>
                    </Row>
                </Column>
            </Grid>
        </Container>
    )
}
