/**
 * To add your routes to the authenticated route list, follow the example below,
 * 1. import the component from your views/pages table
 * 2. create an object inside the defaults array and give it the values provided in the example below.
 * to test this, uncomment both 'import Dashboard from "views/admin/dashboard"' and the object inside the array
 */

// import Dashboard from "views/admin/dashboard"
export default [

    // {
    //     element: <Dashboard />,
    //     path: '/dashboard',
    //     title: 'Dashboard',
    //     isRoot: true,
    //     icon: '',
    //     roles: ['admin'],
    //     items: []
    // },
]