import $ from 'jquery'
export default class Exec {
    constructor(ref, content) {
        this.content = content
        this.wrapper = ref.current
        this.contentIndex = 0
        this.totalContent = content.length
        this.els = $(ref.current).children()
        this.slides = []
        this.interval = null
        this.intervalCounter = 6500
        this.overlayTitle = $(this.wrapper).find('.overlay-title')
        this.overlayContent = $(this.wrapper).find('.overlay-content')
        this.content_index = 0
        this.slider_content_timer = null
        this.span_count = 0
        this.sliced_sentences = []
        this.sliced_characters = []
        this.spanned_charaters = ''
        this.slideTimer = 0;
        this.slideIndex = 0;
        this.nextSlide = 1;
        this.init()
    }
    init() {
        this.start().then(() => {
            clearInterval(this.interval)
            this.interval = setInterval(() => {
                switch (this.contentIndex) {
                    case 0:
                        this.contentIndex += 1
                        break;
                    case 1:
                        this.contentIndex += 1
                        break;
                    case 2:
                        this.contentIndex = 0
                        break;

                    default:
                        break;
                }
                this.animate()
            }, this.intervalCounter);
        })
    }
    async start() {
        clearInterval(this.interval)
        this.write_content()
    }
    animate() {
        this.write_content()
    }


    write_content() {
        this.span_count = 0
        $(this.overlayTitle).empty()
        this.sliced_sentences = this.content[this.contentIndex].title.split(' ')
        for (let sentence of this.sliced_sentences) {
            this.sliced_characters = sentence.split('')
            this.spanned_charaters = ''
            for (let character of this.sliced_characters) {
                this.spanned_charaters += `<span class="animated">${character}</span>`
            }
            $(this.overlayTitle).append(` <div class="sentence-wrapper mr-4">${this.spanned_charaters}</div>`)
        }
        clearInterval(this.slider_content_timer)
        this.slider_content_timer = setInterval(() => {
            this.spanned_charaters = this.overlayTitle.find('.animated')
            $(this.spanned_charaters[this.span_count]).addClass('show')
            this.span_count++
            if (this.span_count == this.spanned_charaters.length) {
                clearInterval(this.slider_content_timer)
                this.span_count = 0
                $(this.overlayContent).text(this.content[this.contentIndex].content)
            }
            return
        }, 5);


    }

}