import Container from 'components/shared/Container'
import Flex from 'components/shared/Flex'
import Grid from 'components/shared/Grid'
import Link from 'components/shared/Link'
import Title from 'components/shared/Title'
import { IMG_PATH } from 'constants'
import React from 'react'
import { BsCashCoin, BsCollection, BsInfoCircle } from 'react-icons/bs'
import { FaHome } from 'react-icons/fa'
import Footer from 'views/common/Footer'

export default function NotFound() {
    return (
        <div className='w-full'>
            <Container
                className=" w-full h-[100vh]"
            >
                <Flex className="bg-[#161741] h-[80%]" direction="column">

                    <Grid className="w-[60%]">
                        <Flex >
                            <img src={`${IMG_PATH}notfound.png`} style={{ width: 300 }} />
                        </Flex>
                        <Flex direction="column">
                            <Title
                                className="text-white text-center font-bold"
                                title="404"
                                subClass="text-white text-center mt-5"
                                style={{
                                    fontSize: 80
                                }}
                                subTitle="The page you are looking for is not found."
                            />
                            <small className='text-white'>Please make sure the page you type in the url is available on StartApp ERP</small>
                        </Flex>
                    </Grid>
                    <div className='w-3/4 h-[3px] bg-gray-400 my-3'></div>
                    <div className='w-3/4 flex items-center justify-start my-4'>
                        <Link to='/home'> <small className='flex items-center justify-center mr-5'><FaHome className='mr-3' />Go Back Home</small></Link>
                        <Link to='/pricing'><small className='flex items-center justify-center mr-5'> <BsCashCoin className='mr-3' />Pricing</small></Link>
                        <Link to='/about'> <small className='flex items-center justify-center mr-5'><BsInfoCircle className='mr-3' />About Us</small></Link>
                    </div>

                </Flex>
                <Footer />
            </Container>

        </div>
    )
}
