import Slider from 'components/shared/Slider'
import { IMG_PATH } from 'constants'
import React from 'react'
import Overlay from './overlay/Overlay'
export default function Head() {
    const slides = [
        {
            style: { backgroundImage: `url(${IMG_PATH}landing1.jpg)` },
            className: "flex items-center justify-center border-0",
        },
        {
            style: { backgroundImage: `url(${IMG_PATH}landing1.png)` },
            className: "flex items-center justify-center border-0"
        },
        {
            style: { backgroundImage: `url(${IMG_PATH}landing2.png)` },
            className: "flex items-center justify-center border-0"
        },
    ]

    return (
        <div className='w-full h-[100vh] bg-[#161741]'>
            <Slider
                enableArrows={false}
                slides={slides}
                transitionDuration={1}
                interval={6000}
                overlay={<Overlay />}
            />
        </div>
    )
}
