import Card from 'components/shared/Card'
import Column from 'components/shared/Column'
import Pill from 'components/shared/Pill'
import Row from 'components/shared/Row'
import React from 'react'

export default function BenefitsCard({ data }) {

    return (
        <Card className="bg-white shadow-darker">
            <Column>
                <Row>
                    <Pill color='#161741' innerPillColor="white" size={15} />
                    <h6 className='font-bold'>{data.title}</h6>
                </Row>
                <Row className="benefit-content ml-6">
                    <p >
                        {data.content}
                    </p>
                </Row>
            </Column>
        </Card>
    )
}
