

import 'css/about/main-about.css'
import Footer from 'views/common/Footer';
import MainAbout from './MainAbout';

const About = () => {
    return (
        <>
            <MainAbout />
            
            <Footer />
        </>
    )
}

export default About;