import Card from 'components/shared/Card'
import Container from 'components/shared/Container'
import Flex from 'components/shared/Flex'
import Grid from 'components/shared/Grid'
import Image from 'components/shared/Image'
import Title from 'components/shared/Title'
import { IMG_PATH } from 'constants'
import { whyContent } from 'constants/data'
import { Unique } from 'functions/common'
import React from 'react'
import WhyCard from './WhyCard'

export default function WhyUseStartapperp() {
    return (
        <Container className="mx-95">
            <Flex direction="column">
                <Title
                    wrapperClassName="items-center justify-center mt-10"
                    title="Why use Startapp ERP?"
                    className="text-[#161741] font-bold"
                    subTitle="StartAPP ERP systems enable businesses to have greater insight and visibility into 
                    their operations. Access to this key information allows them to make critical business decisions and to maintain a 
                    competitive edge in their industry, no matter how big or small the competition may be."
                    subClass="why-use text-gray-600 my-5 text-center"
                    style={{ fontSize: 50 }}
                />
                <Grid columns={4} gap={30} className="mt-10 why-list-grid">
                    {
                        whyContent.map((benefit, index) => <WhyCard key={Unique()} className={index === 3 ? 'why-card-4' : ''} data={benefit} />)
                    }
                </Grid>
            </Flex>
        </Container>
    )
}
