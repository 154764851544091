import React from 'react'
import MainHeader from './MainHeader'
import SubHeader from './SubHeader'
import '../../../css/header.css'
export default function Index() {
    return (
        <>
            <MainHeader />
            <SubHeader />
        </>
    )
}
